"use client"

import { useState, useMemo, useEffect } from 'react'
import { Github, MessageCircle } from 'lucide-react'

export default function HexagonalCircleGenerator() {
  const [radius, setRadius] = useState(10)
  const [displayedRadius, setDisplayedRadius] = useState(10)
  const maxRadius = 50

  const hexSize = useMemo(() => {
    const maxSvgSize = Math.min(window.innerWidth, window.innerHeight) * 0.8
    const circumradius = (2 / Math.sqrt(3)) * displayedRadius
    return maxSvgSize / ((2 * circumradius + 4) * 2)
  }, [displayedRadius])

  const gridSize = useMemo(() => {
    const circumradius = (2 / Math.sqrt(3)) * displayedRadius
    return Math.ceil(2 * circumradius) + 4
  }, [displayedRadius])

  const Hexagon = ({
    x,
    y,
    isOnCircumference,
    isCenter,
  }: {
    x: number
    y: number
    isOnCircumference: boolean
    isCenter: boolean
  }) => {
    const points = [
      [hexSize, 0],
      [hexSize / 2, Math.sqrt(3) * hexSize / 2],
      [-hexSize / 2, Math.sqrt(3) * hexSize / 2],
      [-hexSize, 0],
      [-hexSize / 2, -Math.sqrt(3) * hexSize / 2],
      [hexSize / 2, -Math.sqrt(3) * hexSize / 2],
    ]
      .map(([px, py]) => `${px + x},${py + y}`)
      .join(' ')

    return (
      <polygon
        points={points}
        fill={isCenter ? "#3b82f6" : isOnCircumference ? "#3b82f6" : "transparent"}
        stroke="#d1d5db"
        strokeWidth="1"
      />
    )
  }

  const hexagons = useMemo(() => {
    const generateHexagonalCircle = () => {
      const hexagons = []
      const center = Math.floor(gridSize / 2)

      for (let q = -center; q <= center; q++) {
        for (let r = -center; r <= center; r++) {
          const s = -q - r
          if (Math.abs(s) <= center) {
            const x = hexSize * (3 / 2) * q
            const y = hexSize * ((Math.sqrt(3) / 2) * q + Math.sqrt(3) * r)
            const distance = Math.sqrt(q * q + r * r + s * s) / Math.sqrt(2)
            const isOnCircumference = Math.abs(distance - displayedRadius) < 0.5
            const isCenter = q === 0 && r === 0 && s === 0
            hexagons.push(
              <Hexagon
                key={`${q},${r}`}
                x={x}
                y={y}
                isOnCircumference={isOnCircumference}
                isCenter={isCenter}
              />
            )
          }
        }
      }
      return hexagons
    }

    return generateHexagonalCircle()
  }, [displayedRadius, gridSize, hexSize])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayedRadius(radius)
    }, 300)

    return () => clearTimeout(timer)
  }, [radius])

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    if (!isNaN(value) && value >= 1 && value <= maxRadius) {
      setRadius(value)
    }
  }

  const svgSize = (gridSize + 1) * hexSize * 2

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow flex flex-col items-center space-y-4 p-4">
        <h1 className="text-2xl font-bold">Hexagonal Circle Generator</h1>

        <div className="flex items-center w-full max-w-sm space-x-4">
          <label htmlFor="radius-input" className="text-sm font-medium">
            Radius:   
          </label>
          <input
            type="range"
            id="radius-input"
            min="1"
            max={maxRadius}
            value={radius}
            onChange={handleRadiusChange}
            className="flex-1"
          />
          <input
            type="number"
            min="1"
            max={maxRadius}
            value={radius}
            onChange={handleRadiusChange}
            className="w-20 border border-gray-300 p-2 rounded"
          />
        </div>

        <div className="border border-gray-300 rounded-lg overflow-hidden bg-white">
          <svg
            width={svgSize}
            height={svgSize}
            viewBox={`${-svgSize / 2} ${-svgSize / 2} ${svgSize} ${svgSize}`}
          >
            {hexagons}
          </svg>
        </div>
      </main>

      <footer className="mt-8 pb-6 flex justify-center">
        <a
          href="https://github.com/SirReal314/PlanetSmith-circle"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-gray-600 hover:text-gray-900 transition-colors mr-8"
        >
          <Github size={28} className="mr-3" />
          <span className="text-lg">GitHub</span>
        </a>
        <a
          href="https://discord.com/invite/YqMpxdq8k2"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-gray-600 hover:text-gray-900 transition-colors"
        >
          <MessageCircle size={28} className="mr-3" />
          <span className="text-lg">Discord</span>
        </a>
      </footer>
    </div>
  )
}